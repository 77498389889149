import React from "react";
import LandingTemplate from "@src/components/partials/landing/LandingTemplate";
import LandingForm from "@src/components/partials/landing/LandingForm";
import PrivacyImg from "./privacy.png";

export default function Privacy() {
  const props = {
    image: PrivacyImg,
    title: "Safe, secure, and 100% private.",
    body: "With ClassDojo for Districts, your private information stays just that: private. We keep it secure at all times, and we never share it with anyone. All activity on ClassDojo is safeguarded under full COPPA and FERPA compliance, and GDPR in Europe.",
    cta: "Get our 6-step security plan",
    Form: (
      <LandingForm
        submitLabel="Get our 6-step security plan"
        title="Protect your district with our 6-step security plan"
        name="privacy"
        version="1"
        actionUrl="https://learn.classdojo.com/l/1046033/2025-02-21/ydtv"
      />
    ),
  };

  return <LandingTemplate {...props} />;
}
